<template>
  <v-form @submit.prevent="validate() && submit()" ref="form">
    <v-card
      v-if="totalize && totalize.length > 0"
      :dark="dark"
      class="mt-6 pa-0"
    >
      <v-card-text>
        <v-toolbar flat dense short height="35">
          <v-toolbar-title
            :class="`subtitle-2 font-weight-bold ${color}--text ml-n4`"
            >Totais</v-toolbar-title
          >
        </v-toolbar>
        <v-divider class="mb-3"></v-divider>

        <v-list-item class="ma-0 pa-0">
          <v-list-item-content class="ma-0 pa-0 d-inline-block">
            <v-list-item-subtitle
              v-for="({ label, text }, idx, key) in totalize"
              :key="key"
            >
              <b>{{ label }}:</b> {{ text }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
    </v-card>

    <v-card :dark="dark" class="mt-6 pa-0">
      <v-card-text>
        <FormDataTableToolbar
          v-if="!readonly"
          :readonly="readonly"
          :exportXls="exportXls"
          :color="color"
          @exportXlsx="exportXlsx"
          @importXlsx="importXlsx"
        >
          <template #prepend-actions>
            <div class="pr-5 mr- mb-1" v-if="computedCalculaPreco">
              <v-spacer></v-spacer>
              <v-btn 
                small 
                outlined
                color="primary" 
                class="no-hover mr-n4"
                @click="calculaPreco"
                >
                Calcular Preço
              </v-btn>
            </div> 
            <FormDataTableBatch
              v-if="!readonly"
              :table="table"
              :headers="headers"
              @apply="applyBatch"
            >
              <template #append>
                <v-card v-show="!isFiltered" flat color="warning lighten-3">
                  <v-card-text>
                    <v-icon left>mdi-alert</v-icon>
                    ALERTA: Nenhum filtro foi selecionado, as alterações serão
                    aplicadas em TODOS os itens.
                  </v-card-text>
                </v-card>
              </template>
            </FormDataTableBatch>
          </template>
        </FormDataTableToolbar>
        <v-divider class="mb-3"></v-divider>

        <v-data-table
          v-if="computedFilters && computedFilters.length > 0"
          :headers="computedHeaders"
          :items="table ? table.rows : []"
          :search="search.value"
          :footer-props="{ itemsPerPageOptions: [30, 50, 100, -1] }"
          :custom-sort="customSort"
          :custom-filter="customFilter"
          dense
          item-key="id"
          loading-text="Carregando... Por favor aguarde"
          @current-items="currentItems = $event"
          @update:items-per-page="
            itemsPerPage = $event;
            scrollTop();
          "
          ref="dataTable"
        >
          <template #top>
            <!-- Filters | Search -->
            <FormDataTableFilters
              ref="form-data-table-filters"
              :filters="computedFilters"
              :filtersModels="filtersModels"
              :search="search"
            />

            <!-- Validations Errors -->
            <v-row v-show="warningCells.length > 0" no-gutters class="mb-4">
              <v-col cols="12">
                <FormDataTableWarnings
                  :table="table"
                  @click:goToCell="scrollToCell"
                  @change:warningCells="warningCells = $event"
                />
              </v-col>
            </v-row>

            <!-- Validations Errors -->
            <v-row v-show="invalidCells.length > 0" no-gutters class="mb-4">
              <v-col cols="12">
                <FormDataTableErrors
                  :table="table"
                  @click:goToCell="scrollToCell"
                  @change:invalidCells="invalidCells = $event"
                />
              </v-col>
            </v-row>
          </template>
          <!-- Item -->
          <template #item="{ item: row, index: line }">
            <tr
              class="form-data-table__row"
              :class="{ 'red lighten-4': !row.validated(), 'yellow lighten-4': row.validated() && row.warned(), 'purple lighten-4': row.validated() && !row.warned() && row.infoSA7() }"
              :ref="`form-data-table-tr--${line}`"
            >
              <td
                v-for="(cell, col) in row.cells"
                v-show="(typeof cell.header.hide == 'function') ? !cell.header.hide({readonly, formMaster, user, userRules}) : !cell.header.hide"
                :key="line + col"
                :ref="`form-data-table-td--${line}`"
                :id="`line-${cell.y}`"
                class="pa-0 ma-0 text-center"
              >
                <template v-if="cell.header.type == 'select'">
                  <v-select                                  
                    :ref="`form-data-table__button--${col}-${line}`"
                    :items='cell.header.items'
                    v-model="cell.value"
                    :disabled="cell.header.disabled"
                    @change="(value) => {
                        inputSelect({ row, cell }, value)                                        
                    }">
                  </v-select> 
                </template>
                <template v-else-if="cell.header.type == 'images'">
                  <v-sheet
                    height="60"
                    width="60"
                    class="rounded-circle overflow-hidden d-flex mx-auto"
                  >
                    <v-img
                      @click="callDialog(cell)"
                      v-if="cell.value && cell.value.length > 0"
                      :src="cell.value[0]"
                      max-height="60"
                      max-width="60"
                      contain
                      class="clickable"
                    ></v-img>
                    <v-gravatar v-else default-img="mp"/>
                  </v-sheet>
                </template>
                <template v-else>
                  <button
                    v-show="editing != cell"
                    :ref="`form-data-table__button--${col}-${line}`"
                    :title="cell.validated ? cell.warned ? cell.warnMessages.map(str => str.replace(/<[^>]*>/g, '')).reduce((acc,cur) => `${acc}\n${cur}`) : undefined : cell.errorMessages.map(str => str.replace(/<[^>]*>/g, '')).reduce((acc,cur) => `${acc}; ${cur}`) "
                    :class="`form-data-table__button ${(cell.header.align == 'center') ? 'mx-auto' : (cell.header.align == 'end') ? 'ml-auto' : '' }  ${(cell.editable) ? 'primary--text' : ''} ${cell.validated? '' : 'form-data-table__button--invalid'} ${cell.warned && cell.validated ? 'form-data-table__button--warned' : ''}`"
                    @keydown.up="line == 0 || focusButton(col, line-1)"
                    @keydown.down="line == currentItems.length-1 || focusButton(col, line+1)"
                    @keydown.left="col == 0 || focusButton(col-1, line, -1)"
                    @keydown.right="col == headers.length-1 || focusButton(col+1, line, 1)"
                    @keydown.exact.tab.prevent="col == headers.length-1 || focusButton(col+1, line, 1)"
                    @keydown.shift.tab.prevent="col == 0 || focusButton(col-1, line, -1)"
                    @keydown.enter="cell.editable && (locked = true, clickButton(cell, col, line))"
                    @keydown.exact.prevent="handleButtonKeydown({cell, col, line}, $event)"
                    type="button"
                  >
                    {{ cell.text }}
                  </button>
                  <input
                    v-if="editing == cell"
                    :ref="`form-data-table__input--${col}-${line}`"
                    :value="stuckInput || cell.value"
                    :style="{ width: cell.header.width + 'px', minWidth: '80px'}"
                    :type="
                      cell.header.type == 'float' || cell.header.type == 'integer'
                        ? 'number'
                        : 'text'
                    "
                    @blur="inputBlur({ row, cell }, $event)"
                    @keydown.exact.enter="
                      line < currentItems.length - 1
                        ? focusButton(col, line + 1)
                        : ($event.target.blur(), focusButtonItself(col, line))
                    "
                    @keydown.shift.enter="
                      line > 0
                        ? focusButton(col, line - 1)
                        : ($event.target.blur(), focusButtonItself(col, line))
                    "
                    @keydown.up="
                      locked ||
                        line == 0 ||
                        ($event.preventDefault(), focusButton(col, line - 1))
                    "
                    @keydown.down="
                      locked ||
                        line == currentItems.length - 1 ||
                        ($event.preventDefault(), focusButton(col, line + 1))
                    "
                    @keydown.left="
                      locked ||
                        col == 0 ||
                        ($event.preventDefault(), focusButton(col - 1, line))
                    "
                    @keydown.right="
                      locked ||
                        col == computedHeaders.length - 1 ||
                        ($event.preventDefault(), focusButton(col + 1, line))
                    "
                    @keydown.esc="
                      (hasCancelled = true),
                        $event.target.blur(),
                        focusButtonItself(col, line)
                    "
                    class="form-data-table__input"
                  />
                </template>
              </td>
            </tr>
          </template>

          <!-- No Data  -->
          <template #no-data>
            <v-alert
              :value="true"
              color="error"
              icon="mdi-alert-outline"
              class="mt-3"
            >
              <span v-if="inputsDetailsFiltered">Os filtros automáticos não retornaram registros.</span>
              <span v-else>Não há dados disponíveis.</span>
            </v-alert>
          </template>
          
          <!-- No Results  -->
          <template #no-results>
            <v-alert
              :value="true"
              color="error"
              icon="mdi-alert-outline"
              class="mt-3"
            >
              <span v-if="search.value.length > 0">A pesquisa por "{{ search.value }}" não retornou registros, confira os filtros selecionados.</span>
              <span v-else>Os filtros selecionados não retornaram registros.</span>
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { Cell } from "../../plugins/cell";
const { mapState:registersState, mapActions:registersActions, mapMutations:registersMutations } = createNamespacedHelpers('registers')
import * as XLSX from "xlsx";
import FormDataTableFilters from "@/components/Registers/FormDataTableFilters";
import FormDataTableToolbar from "@/components/Registers/FormDataTableToolbar";
import FormDataTableErrors from "@/components/Registers/FormDataTableErrors";
import FormDataTableWarnings from "@/components/Registers/FormDataTableWarnings";
import FormDataTableBatch from "@/components/Registers/FormDataTableBatch";
import { Table } from "@/plugins/cell";
export default {
  name: "FormDataTable",
  events: ["showCarousel", "calcPreco"],
  components: {
    FormDataTableFilters,
    FormDataTableToolbar,
    FormDataTableErrors,
    FormDataTableWarnings,
    FormDataTableBatch,
  },
  props: {
    props: Object,
    headers: Array,
    formMaster: Object,
    items: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: "teal",
    },
    dark: {
      type: Boolean,
      default: false,
    },
    exportXls: {
      type: Boolean,
      default: true,
    },
    xlsxName: {
      type: String,
      default: "itens",
    },
    filterItems: {
      type: Function,
      default: (item) => item,
    },
    itemsDetails: {
      type: Array,
      default: () => [],
    },
    SA7off: {
      type: Boolean,
      default: false,
    },
    inputsDetailsFiltered: Boolean,
    user: Object,
    userRules: Array
  },

  data() {
    return {
      total: "",
      hasCancelled: false,
      currentItems: [],
      search: {
        value: "",
      },
      dataSA7Off: false,
      openFilter: false,
      filtersModels: {
        invalidCells: false,
        warningCells: false,
        filterSA7: this.props.tableSA7,
      },
      itemsPerPage: 10,
      editing: {},
      stuckInput: "",
      locked: false,
      table: null,
      invalidCells: [],
      warningCells: [],
      duplicateRows: []
    }
  },

  computed: {
    ...registersState(['registers']),
    computedCalculaPreco(){
      return this.items ? (this.items[0] ? (this.items[0].Z31_EAN): false) : false
    },
    computedHeaders() {
      const headers = this.headers.filter((h) => (typeof h.hide == 'function') ? !h.hide({readonly: this.readonly, formMaster: this.formMaster, user: this.user, userRules: this.userRules}) : !h.hide);
      return this.readonly
        ? headers.map((h) => {
            return {
              ...h,
              editable: false,
            };
          })
        : headers.map((h) => {
            return {
              ...h,
            };
          });
    },
    computedFilters() {
      const filters = this.headers.filter((x) => x.filterable === true);
      filters.forEach((el) => {
        const ixH = this.headers.findIndex((x) => x.value === el.value);
        switch (el.filterType) {
          case "switch":
            this.headers[ixH].filter = (header, search, row) => {
              const cell = row.getCellFromHeader(header);
              const validated = cell.value || !this.filtersModels[el.value];
              if (this.filtersModels.invalidCells)
                return validated && !row.validated()
              if (this.filtersModels.warningCells)
                return row.warned()
              return validated
            }
            break;

          default:
            this.headers[ixH].filterItems = [
              ...new Set(this.items.map((x) => x[el.value])),
            ];
            this.headers[ixH].filter = (header, search, row) => {
              if (!this.filtersModels[el.value]) return true;
              if (this.filtersModels[el.value].length === 0) return true;

              const cell = row.getCellFromHeader(header);
              const validated =
                this.filtersModels[el.value].findIndex((n) => n == cell.value) >
                -1;
              if (this.filtersModels.invalidCells)
                return validated && !row.validated()
              if (this.filtersModels.warningCells)
                return row.warned()
              return validated
            }
            break;
        }
      });

      filters.push({
        filterType: 'switch',
        filterLabel: 'Com aviso',
        value: 'warningCells',
        hide: this.readonly
      })
      filters.push({
        filterType: 'switch',
        filterLabel: 'Com erro',
        value: 'invalidCells',
        hide: this.readonly
      })
      
      if (this.props.tableSA7 && this.props.tableSA7ShowAll) {
        filters.unshift({
          filterType: 'switch',
          filterLabel: 'Itens do Cliente',
          value: 'filterSA7',
          hide: this.readonly,
          disabled: (this.items.filter(this.filterItems).length > 0 && !this.filtersModels.filterSA7)
        })
      }

      return filters
    },
    totalize() {
      let totalize = null;

      if (this.items && this.items.length > 0) {
        totalize = [];
        this.headers
          .filter((x) => x.totalize)
          .forEach((elx) => {
            const total = this.items
              .map((x) => x[elx.value])
              .reduce((acc, cur) => (acc || 0) + (cur || 0)) || 0;
            this.total = total;
            const prefix = elx.prefix ? elx.prefix + " " : "";
            const suffix = elx.suffix ? " " + elx.suffix : "";
            totalize.push({
              label: elx.totalizeLabel || elx.text,
              text:
                prefix +
                total.toLocaleString("pt-br", {
                  minimumFractionDigits: elx.precisionText,
                  maximumFractionDigits: elx.precisionText,
                }) +
                suffix,
              value: elx.value,
            });
          });
      }
      return totalize;
    },

    invalidIdentifiers() {
      return this.invalidCells.map((cell) => {
        return {
          cell,
          identifierCells: this.identifierHeaders.reduce((arr, header) => {
            const found = cell.row.getCellFromHeader(header.value);
            if (found) return [...arr, found];
            else return arr;
          }, []),
        };
      });
    },
    validated() {
      return (
        !(this.invalidCells.length > 0) &&
        this.items.filter(this.filterItems).length > 0
      );
    },
    isFiltered() {
      if (this.search.value.length > 0) return true;

      return Object.entries(this.filtersModels).some(([_, model]) => {
        if (Array.isArray(model) && model.length > 0) return true;
        if (typeof model == "boolean" && model) return true;
        return false;
      });
    },
  },

  watch: {
    formMaster(val) {
      let items = this.items
      const headers = this.readonly
        ? this.headers.map(h => ({ ...h, editable: false }))
        : this.headers
      
      for (const hu of headers.filter(h => h.unique)) {
        const duplicatedItems = items.map(e => e[hu.value])
                                    .map((e, i, final) => final.indexOf(e) !== i && i)
                                    .filter(obj=> items[obj])
                                    .map(e => items[e][hu.value])
        
        items = items.map(i => {
          if (duplicatedItems.includes(i[hu.value]))
            i.duplicate = {header: hu}
          return i
        })      
      }
    
      // DESATIVADO PARA EVITAR CALCULO DESNECESSARIO
      // const table = new Table({ headers, items, formMaster: val, formDataTablelegacy: true })
      // this.table = table
    },
    items(items) {
      if (!items) return;

      const headers = this.readonly
        ? this.headers.map((h) => ({ ...h, editable: false }))
        : this.headers;
      for (const hu of headers.filter((h) => h.unique)) {
        const duplicatedItems = items
          .map((e) => e[hu.value])
          .map((e, i, final) => final.indexOf(e) !== i && i)
          .filter((obj) => items[obj])
          .map((e) => items[e][hu.value]);

        items = items.map((i) => {
          if (duplicatedItems.includes(i[hu.value]))
            i.duplicate = { header: hu };
          return i;
        });
      }
      headers.forEach(header => {
        if((typeof header.computedEver == 'string' && header.computedEver.includes('=>')) || (typeof header.computedEver == 'function')){
          try {
            const evalFun = eval(header.computedEver)
            items = items.map(i =>{
              const result = evalFun({item:i})
              i[header?.value] = result
              header.default = result
              return i
            })
            
          } catch (error) {
            console.error(error)
          }

        }
      })
      const table = new Table({ headers, items, formMaster: this.formMaster, formDataTablelegacy: true, filterItems: this.filterItems });
      this.table = table;
    },

    invalidCells() {
      this.$emit("validated", this.validated);
    },

    'filtersModels.filterSA7'(newValue) {
      this.$emit("filterSA7", newValue);
    },
    
    SA7off(newValue) {
      this.filtersModels.filterSA7 = !newValue
    },
  },

  async created() {
    this.fetchFiltersCollections();
  },

  methods: {
    calculaPreco(){
      this.$emit("calcPreco")
    },
    callDialog(cell) { 
      this.$emit("showCarousel", cell.value);
    },
    ...registersActions(["get", "getById"]),
    submit() {
      this.$emit("submit", this.items);
    },

    validate() {
      return this.$refs.form.validate();
    },

    getModel() {
      return this.items;
    },

    getTotal() {
      return this.total;
    },

    getTable() {
      return this.table;
    },

    getTotalize() {
      return this.totalize;
    },

    customSort(rows, sortBy, sortDesc) {
      if (sortBy.length == 0) return rows;

      // Leva em consideração apenas um item, não permitindo ordenar por mais de uma coluna.
      const { by, desc } = { by: sortBy[0], desc: sortDesc[0] };

      const orderned = rows.sort((rowA, rowB) => {
        const cellA = rowA.getCellFromHeader(by);
        const cellB = rowB.getCellFromHeader(by);
        if (cellA.value < cellB.value) {
          return desc ? 1 : -1;
        } else if (cellA.value > cellB.value) {
          return desc ? -1 : 1;
        }
        return 0;
      });
      return orderned;
    },

    customFilter(headerValue, search, row) {
      const caseSensitive = false;
      const cell = row.getCellFromHeader(headerValue);

      const searchArray = search.split(' ')

      let searchFilter = true;
      if (search) {
        searchFilter = caseSensitive
          ? searchArray.every(s => cell.text.includes(s)) //cell.text.includes(search)
          : searchArray.every(s => cell.text.toLowerCase().includes(s.toLowerCase())) //cell.text.toLowerCase().includes(search.toLowerCase());
      }

      return searchFilter;
    },

    calculateHeaderWidth(cell, line) {
      cell.header.width = this.getColumnWidth(line);
    },

    clickButton(cell, col, line) {
      this.calculateHeaderWidth(cell, line);
      this.editing = cell;
      this.$nextTick(() => {
        this.getInput(col, line).focus();
      });
    },

    handleButtonKeydown({ cell, col, line }, { keyCode, key }) {
      if (!cell.editable) return;

      const isNumber =
        (48 <= keyCode && keyCode <= 57) || (96 <= keyCode && keyCode <= 105);
      const isChar = 65 <= keyCode && keyCode <= 90;
      const isCcdil = keyCode == 186;
      if (isNumber || isChar || isCcdil) {
        if (this.editing == cell) {
          this.stuckInput = this.stuckInput + key;
        } else {
          this.stuckInput = key;
          this.clickButton(cell, col, line);
        }
      }
    },

    async inputBlur({ cell, row }, event) {
      this.editing = null;
      this.locked = false;
      this.stuckInput = "";
      if (this.hasCancelled) this.hasCancelled = false;
      else {
        await row.process({ value: event.target.value, cell });
        this.$emit("validated", this.validated);
      }
    },
    
    async inputSelect({ cell, row }, value) {
      this.editing = null;
      this.locked = false;
      this.stuckInput = "";
      await row.process({ value: value, cell });
      this.$emit("validated", this.validated);
    },

    getButton(col, line, signal = 1) {
      let colPosition = col;
      for (let i = col; signal ? (i < this.headers.length) : 0; i += signal) {
        const header = this.headers[i];
        if (!header) { break }
        else if (!header.hide) {
          colPosition = i;
          break;
        }
      }
      return this.$refs[`form-data-table__button--${colPosition}-${line}`][0]
    },

    getInput(col, line) {
      return this.$refs[`form-data-table__input--${col}-${line}`][0];
    },

    getTd(line) {
      return this.$refs[`form-data-table-td--${line}`][0];
    },

    focusButton(x, y, signal) {
      const button = this.getButton(x, y, signal)
      button.focus()
      return true
    },

    focusButtonItself(col, line) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.focusButton(col, line);
        });
      });
    },

    getColumnWidth(line) {
      const td = this.getTd(line);
      return td.clientWidth;
    },

    async fetchFiltersCollections() {
      await Promise.all(
        this.filters.map(async (filter) => {
          if (filter.method && filter.method.collectionName) {
            const response = await this.get({
              collection: filter.method.collectionName,
              register: filter.method.collectionName,
              orderBy: "indice",
            });
            filter.method.values = response.map(
              (item) => item[filter.method.collectionField]
            );
            return response;
          }
          return Promise.resolve();
        })
      );
    },

    exportXlsx() {
      const headers = this.headers.filter((h) => h.export);
      const header = headers.map((h) => h.text);
      const items = this.items.map((item) =>
        headers.reduce(
          (acc, header) => ({ ...acc, [header.text]: item[header.value] }),
          {}
        )
      );
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(items, { header });

      // adiciona as fórmulas em formato excel para as células das colunas que possuem essa configuração
      items.slice(1).forEach((_, idx) => {
        headers.forEach((header, i) => {
          if (!header.xlsxFormula) return;

          const cellKey = `${String.fromCharCode(65 + i)}${idx + 2}`;
          try {
            ws[cellKey].f = header.xlsxFormula
              .trim()
              .replaceAll("x", `${idx + 2}`);
          } catch (err) {
            console.error(err);
          }
        });
      });

      XLSX.utils.book_append_sheet(wb, ws, `Sheet`);

      const exportFileName = `${this.xlsxName}.xlsx`;
      XLSX.writeFile(wb, exportFileName);
    },

    importXlsx(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });

          /* Seleciona a primeira página */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          /*
           * Transforma os dados da página em um array com as linhas da página, onde cada linha também é um array com os itens ordenados pela coluna.
           * data[0] = primeira linha, que é a linha dos headers
           * data[1...n] = todos os dados da tabela
           */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          const headers = data[0];
          const items = data.slice(1);

          /*
           * Filtra os headers que possuem valor correspondente com alguma coluna na tabela atual
           * importables = array com os headers editáveis
           * identifiers = array com os headers que são identificadores (é assumido que os valores sao únicos)
           */
          const { importables, identifiers } = headers.reduce(
            ({ importables, identifiers }, value, idx) => {
              const header = this.headers.find((h) => h.text == value);
              const newEditables =
                header && header.import
                  ? [
                      ...importables,
                      {
                        headerText: header.text,
                        headerValue: header.value,
                        value,
                        idx,
                      },
                    ]
                  : importables;
              const newIdentifiers =
                header && header.identifier
                  ? [
                      ...identifiers,
                      {
                        headerText: header.text,
                        headerValue: header.value,
                        value,
                        idx,
                      },
                    ]
                  : identifiers;
              return {
                importables: newEditables,
                identifiers: newIdentifiers,
              };
            },
            { importables: [], identifiers: [] }
          );

          items.forEach((row) => {
            /*
             * percorre cada coluna identificadora (`identifiers`) para fazer as validações;
             * busca o item que corresponde com o valor presente em cada coluna identificadora da linha atual da planilha (`row`)
             * verifica se todos os resultados são do mesmo item (para caso de haver mais de uma coluna identificadora e os valores correspondentes no item serem de itens diferentes)
             */
            let i = 0;
            let lastItemIdx = null;
            let currentItemIdx = null;
            do {
              lastItemIdx = currentItemIdx;
              currentItemIdx = this.items.findIndex(
                (item) =>
                  item[identifiers[i].headerValue] == row[identifiers[i].idx]
              );

              // caso não encontre um item correspondente, ou corresponda a mais de um item não atualiza o item
              if (
                currentItemIdx == -1 ||
                (lastItemIdx != null && lastItemIdx != currentItemIdx)
              )
                return;

              i++;
            } while (i < identifiers.length);

            const idx = currentItemIdx;
            const item = this.items[idx];
            const tableRow = this.table.findRow(item);
            for (const importable of importables) {
              const header = this.headers.find(
                (header) => header.value == importable.headerValue
              );
              const value = row[importable.idx];
              const cell =
                tableRow.cells[this.table.headersHashMap[header.value]];
              tableRow.process({ cell, value });
            }
          });

          // @TODO considerar a viabilidade de usar raeder.onloadend
          this.$toast.success("Arquivo importado!", { position: "bottom" });
          this.$emit("validated", this.validated);
        } catch (err) {
          this.$toast.error("Falha ao importar", { position: "bottom" });
          console.error(err);
        }
      };

      reader.onerror = (err) => {
        this.$toast.error("Falha ao importar", { position: "bottom" });
        console.error(err);
      };

      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!["xlsx", "xls"].includes(fileExtension)) {
        this.$toast.error("Falha ao importar: Formato não suportado.", {
          position: "bottom",
        });
        return;
      }

      reader.readAsBinaryString(file);
    },

    scrollTop() {
      const el = this.$refs.dataTable.$el;
      if (el) {
        this.$nextTick(() => {
          el.scrollIntoView(false);
        });
      }
    },

    scrollToLine(line, options) {
      const el = this.$refs[`form-data-table-tr--${line}`];
      if (el) {
        el.scrollIntoView(options);
      }
    },

    scrollToCell({cell, filter}) {
      this.clearAndActiveFilter(filter)
      this.$nextTick(() => {
        const pos = this.currentCellPosition(cell);
        if (pos) {
          this.scrollToLine(pos.line);
          this.focusButton(pos.col, pos.line);
        } else {
          console.warn(
            `scrollToCell: Couldn't get current position of cell in column "${cell.header.value}"`
          );
        }
      });
    },

    currentCellPosition(cell) {
      const line = this.currentItems.findIndex(({ line }) => line == cell.y);
      const col = this.headers.findIndex((h) => h.value == cell.header.value);
      if (col >= 0 && line >= 0) return { col, line };
      else return null;
    },

    clearAndActiveFilter(filter) {
      this.$refs['form-data-table-filters'].clearFilters({[filter]: true})
    },

    async applyBatch({ item, inputs }) {
      const filteredItems = this.$refs.dataTable.$children[0].filteredItems;
      for (const row of filteredItems) {
        for (const input of inputs) {
          const value = item[input.value];
          const header = input.value;
          if (!value) continue;
  
          const cell = row.getCellFromHeader(header);
          await row.process({ value, cell });
        }
      }
    },

    print(...args) {
      // console.info(...args)
    },

    proccessItem({ item, head, copy = false }) {
      let ret = this.$_.cloneDeep(item);
      if (!ret) return;
      const self = {
        registers: this.registers,
        item,
      };
      if (
        ret._highlightResult &&
        ret._highlightResult[head.value] &&
        ret._highlightResult[head.value].matchedWords &&
        ret._highlightResult[head.value].matchedWords.length > 0
      )
        ret = ret._highlightResult;

      /* NESTED */
      const pathItem = head.value.split(".");
      const lenItem = pathItem.length;
      if (lenItem == 1) {
        ret = ret[pathItem];
      } else {
        for (let ix = 0; ix < pathItem.length; ix++) {
          const el = pathItem[ix];
          if (ret && typeof ret[el] != "undefined") {
            ret = ret[el];
          } else {
            ret = head.checkbox ? false : "";
            break;
          }
        }
      }

      if (head.computedList) {
        const headComputed = eval(head.computedList);
        ret = headComputed(self);
      }

      /* ASSIST */
      const detail = head.detail;
      if (detail && ret) {
        const pathAssist = detail.split(".");
        const lenAssist = pathAssist.length;
        if (Array.isArray(ret)) {
          ret.forEach((el, ix) => {
            let retEl = this.registers[pathAssist[0]]
              ? this.registers[pathAssist[0]].find((x) => x.id === el)
              : null;
            if (retEl) {
              for (let i = 1; i < lenAssist; i++) {
                const el2 = pathAssist[i];
                retEl = retEl ? retEl[el2] : "";
              }
              ret[ix] = retEl;
            }
          });
        } else {
          let retEl = this.registers[pathAssist[0]]
            ? this.registers[pathAssist[0]].find((x) => x.id === ret)
            : null;
          for (let i = 1; i < lenAssist; i++) {
            const el = pathAssist[i];
            retEl = retEl ? retEl[el] : "";
          }
          ret = retEl;
        }
      }

      /* Images */
      if (head.type == "images") {
        return ret && Array.isArray(ret) && ret.length > 0 ? ret[0].base64 : "";
      }

      /* FORMATS */
      if (!ret) {
        return;
      }

      if (ret && ret.value) {
        ret = ret.value;
      }

      if (head.slice && !copy) {
        ret = ret.slice(head.slice);
      }

      if (head.upper) {
        ret = ret.toUpperCase();
      }

      if (head.lower) {
        ret = ret.toLowerCase();
      }

      if (head.trim) {
        ret = ret.trim();
      }

      /* FORMAT DATES */
      if (head.date || head.dateHour || head.dateYear || head.datePlusWeek) {
        if (Object.prototype.hasOwnProperty.call(ret, "toDate")) {
          ret = ret.toDate();
        } else if (ret.seconds && ret.nanoseconds) {
          // Retorno via Algolia não contem metodo toDate (agora o firebase tbm)
          ret = new this.$db.Timestamp(ret.seconds, ret.nanoseconds).toDate();
          // Retorno via Algolia não contem metodo toDate. (agora tem o underline na frente)
        } else if (ret._seconds && ret._nanoseconds) {
          ret = new this.$db.Timestamp(ret._seconds, ret._nanoseconds).toDate();
        }
      }

      if (head.date) {
        if (typeof ret === "string" && ret.trim() === "") {
          return;
        }
        ret = this.$moment(ret).format("DD/MM/YYYY");
      }

      if (head.dateHour) {
        ret = this.$moment(ret).format("DD/MM/YYYY - HH:mm:ss");
      }

      if (head.dateYear) {
        ret = this.$moment(ret).format("YYYY");
      }

      if (head.datePlusWeek) {
        ret = this.$moment(ret).format("DD/MM/YYYY, dddd");
      }

      if (head.money) {
        ret = new Cell({ calue: ret, header: head }).fixPrecision(
          ret,
          head.precisionValue
        );
        ret =
          "R$" +
          ret.toLocaleString("pt-br", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
      }

      return ret;
    },
  },
};
</script>

<style lang="scss">
.v-badge.small .v-badge__badge {
  width: 16px;
  height: 16px;
  min-width: auto;
}

.form-data-table__validations {
  background-color: rgba(247, 149, 149, 0.534);
}

.form-data-table__row {
  &.form-data-table__row--invalid {
    border: 1px solid rgba(255, 0, 0, 0.33);
    background-color: rgba(247, 149, 149, 0.534);
  }
}

.form-data-table__button {
  display: block;
  height: 100%;
  padding: 0 12px;
  white-space: nowrap;

  &:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }

  .form-data-table__button {
    display: block;
    height: 100%;
    padding: 0 12px;
    white-space: nowrap;

    &:hover {
      background-color: rgba(0, 0, 0, .12);
    }

    &.form-data-table__button--invalid {
      border: 1px solid rgba(255, 0, 0, 0.33);
      border-radius: 3px;
      background-color: #fd484860;
    }
    
    &.form-data-table__button--warned {
      border: 1px solid rgb(227, 206, 29);
      border-radius: 3px;
      background-color: rgb(255, 244, 150);
    }

    &:focus {
      background-color: #eeeeee;
      border: 1px solid rgba(0, 0, 0, .33);
      border-radius: 3px;
      padding: 0 11px;
    }
  }

  &:focus {
    background-color: #eeeeee;
    border: 1px solid rgba(0, 0, 0, 0.33);
    border-radius: 3px;
    padding: 0 11px;
  }
}

.form-data-table__input {
  display: block;
  height: 100%;
  padding: 0 12px;

  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.33);
  border-radius: 3px;
}
</style>