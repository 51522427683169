const FormData = require('form-data');
const stringify = require('json-stringify-safe');
import algoliasearch from 'algoliasearch/lite';

const register = {
  namespaced: true,
  state: {
    registers: {},
    observers: {
      // counts: { snapshots: [] }
    },
    mongodb: {},
    loading: {},
    counters: {},
    lastAlgoliaReqParams: {},
  },
  getters: {
  },
  mutations: {
    setRegister(state, { register, data, unshift = false }) {
      if (!state.registers[register]) { 
        this._vm.$set(state.registers, [register], []) 
      }
      const ix = state.registers[register].findIndex(x => x.id == data.id)
      if (ix > -1) {
        this._vm.$set(state.registers[register], [ix], data)
      } else if (unshift) {
        state.registers[register].unshift(data)
      } else {
        state.registers[register].push(data)
      }
    },
    removeRegister(state, { register, data }) {
      const ix = state.registers[register].findIndex(x => x.id == data.id)
      if (ix > -1) {
        state.registers[register].splice(ix, 1)
      }
    },
    resetRegister(state, register) { 
      this._vm.$set(state.registers, [register], [])
      if (state.observers[register] && state.observers[register].snapshots) {
        state.observers[register].snapshots.forEach(el => {
          el()
        });
      }
      this._vm.$set(state.observers, [register], null)
    },
    resetRegisters(state) { 
      this._vm.$set(state, 'registers', {customers: state.registers.customers}) 
      this._vm.$set(state, 'observers', {/*counts: { snapshots: [] },*/ customers: state.observers.customers})
    },
    setLoading(state, {register, status}) { 
      this._vm.$set(state.loading, [register], status) 
    },
    setCounter(state, { collection, count }) {
      this._vm.$set(state.counters, collection, count)
    },
    resetCounters(state) { state.counters = {} },
  },
  actions: {
    // getCustomer
    getCustomer({ rootState }, collection) {
      if (['users', 'notifications', 'customers', '_configs'].includes(collection) || typeof collection === 'undefined') return ''

      if (rootState.collectionStructure) {
        return rootState.companySelected.groupCompany;
      } else if (collection === 'e') {
        return rootState.companySelected.groupCompany
      }

      return null
    },
    
    // GPEmpresa
    getGpEmpresa({ rootState }, collection) {
      let gpEmpresa = ''
      if (
        collection === 'e' 
        || 
        (
          rootState.collectionStructure 
          && Object.prototype.hasOwnProperty.call(rootState.collectionStructure, collection)
        )
      ) { 
        if (rootState.collectionStructure[collection] && rootState.collectionStructure[collection]['X2_GRUPO']) {
          gpEmpresa = rootState.collectionStructure[collection]['X2_GRUPO'] + '0'
        } else { 
          gpEmpresa = rootState.companySelected.M0_CODIGO + '0'
        }
      }
      
      return gpEmpresa
    },

    // Filial
    getFilial({ rootState }, collection) {
      let filial = ''
      if (collection === 'e') {
        // Força Exclusivo
        filial = rootState.companySelected.M0_CODFIL
      } else if (collection != '_configs' && rootState.collectionStructure  && Object.prototype.hasOwnProperty.call(rootState.collectionStructure, collection)) {
        if (rootState.companySelected.M0_NOME == 'ORE') {
          // TODO Após compilação retirar legado
          rootState.companySelected.M0_LEIAUTE = 'EEFF'
          if (collection == 'SA1') rootState.companySelected.M0_LEIAUTE = 'FFFF'
        }

        const X2_MODOEMP = rootState.collectionStructure[collection]['X2_MODOEMP']
        const X2_MODOUN = rootState.collectionStructure[collection]['X2_MODOUN']
        const X2_MODO = rootState.collectionStructure[collection]['X2_MODO']

        // Compartilhado
        if (
          X2_MODOEMP == 'C'
          && X2_MODOUN == 'C'
          && X2_MODO == 'C'
        ) {
          filial = ' '.repeat(rootState.companySelected.M0_SIZEFIL)
        }

        // Exclusivo
        else if (
          X2_MODOEMP == 'E'
          && X2_MODOUN == 'E'
          && X2_MODO == 'E'
        ) {
          filial = rootState.companySelected.M0_CODFIL
        }

        // Exclusivo/Compartilhado
        else {
          let leiaute = rootState.companySelected.M0_LEIAUTE || 'FF'
          leiaute = leiaute.split("")
          
          const leiaute_E = leiaute.filter(x => x == 'E')
          const leiaute_U = leiaute.filter(x => x == 'U')
          const leiaute_F = leiaute.filter(x => x == 'F')
          
          if (leiaute_E.length > 0) filial += (X2_MODOEMP == 'C') ? ' '.repeat(leiaute_E.length) : rootState.companySelected.M0_CODFIL.substr(0, leiaute_E.length)
          if (leiaute_U.length > 0) filial += (X2_MODOUN == 'C') ? ' '.repeat(leiaute_U.length) : rootState.companySelected.M0_CODFIL.substr(filial.length, leiaute_U.length)
          if (leiaute_F.length > 0) filial += (X2_MODO == 'C') ? ' '.repeat(leiaute_F.length) : rootState.companySelected.M0_CODFIL.substr(filial.length, leiaute_F.length)
          // console.info('collection => ', collection, 'x2 => ', rootState.collectionStructure[collection], 'leiaute =>', leiaute_E, leiaute_U, leiaute_F, 'filial => ', filial)
        }
      } 
      return filial
    },

    mountedFilters({ state, rootState }, { ref, filters, register, forceFilter = false, tenantId = null, userLogged = null }) {
      try {
        const user = userLogged || rootState.auth.user // Disponivel para Filtro
        const userRules = state.registers.userRules
        const rules = (user?.companies && user?.companies[tenantId]?.rules) ? userRules.filter(x => user?.companies[tenantId]?.rules.includes(x.id)) : []
        const registerOriginal = (register) ? register.replace('Filtered','') : register

        // DESABILITA FILTROS PARA SUPER ADMIN OU PAPEL ESTEJA LIBERADO
        if (
          !forceFilter && 
          (
            tenantId && (
              user?.superAdmin ||
              (user?.companies && user?.companies[tenantId]?.admin) || 
              ( 
                rules
                && rules.some(x => x.claims && x.claims[registerOriginal] && x.claims[registerOriginal].noFilters)
              )
            )
          )
        ) {
          return ref || []
        }

        let ret = []

        filters.forEach(el => {
          let { key, operator, value, expectedValue, when = true, allowEmpty = false } = el
          // console.info('FILTER =>', key, operator, value, expectedValue)

          if (typeof when === 'boolean' && !when) {
            return;
          } else if (typeof when == 'string' && when.includes('${')) {
            const whenEval = eval(when)
            if (typeof whenEval === 'string' && !eval(whenEval)) {
              return;
            } else if (typeof whenEval === 'boolean' && !whenEval) {
              return;
            }
          }

          // Validation
          if (
            !key
            || !operator
            || (typeof value != 'boolean' && !value && !allowEmpty)
            || (Array.isArray(value) && !value.length > 0)
          ) throw { error: 'Malformed Filter', filter: el, register }

          if (typeof value == 'string' && key.includes('${')) {
            key = eval(key)
          }

          if (typeof value == 'string' && value.includes('${')) {
            value = eval(value)
          }

          // Array
          if (value == undefined || value == 'undefined') { 
            value = ''
          }

          if (typeof value == 'string' && value.includes(',') && expectedValue && expectedValue === 'array') {
            value = value.split(",")
          } else if (typeof value == 'string' && value && expectedValue && expectedValue === 'array') { 
            value = [value]
          }

          // console.info('FORMATED FILTER =>', key, operator, value, user)
          if (!!key && (typeof value != 'boolean' && !value && !allowEmpty)) {
            throw { error: 'Empty Filter', filter: el, register }
          } else if (ref) {
            ref = ref.where(key, operator, value)
          } else {
            ret.push({ key, operator, value })
          }
        })

        return (ref) ? ref : ret
      } catch (error) {
        console.error(error, filters, register)
        return null
      }
    },

    // OBSERVER
    async observer({ state, commit, dispatch, rootState }, { collection, register, filters, disableIntFilters = false, details, orderBy, limit = 30, forceFilter = false, query, notUnshift }) {
      const user = rootState.auth.user
      const integration = (state.registers.integrations) ? state.registers.integrations.find(x => x.collection === collection) : null

      if (!state.registers[register]) { 
        this._vm.$set(state.registers, [register], []) 
      }

      if (!state.loading[register]) { 
        this._vm.$set(state.loading, [register], true) 
      }
     
      // PREPARE ENVIROMENT
      const tenantId = await dispatch('getCustomer', collection)
      if (tenantId === null || typeof tenantId === 'undefined') {
        throw new Error(`Nenhum ID do Grupo encontrado. Collection: ${collection}`)
      }
      const gpEmpresa = await dispatch('getGpEmpresa', collection)
      const filial = await dispatch('getFilial', collection)
      
      collection = tenantId ? `customers/${tenantId}/${collection}` : collection

      let ref = this._vm.$db().collection(collection)
      
      // FILTERS
      if (filters) ref = await dispatch('mountedFilters', { ref, filters, register, forceFilter, tenantId, userLogged: user })
      if (integration && !disableIntFilters) {
        let { filtersSaller, filtersClient } = integration
        if (filtersClient && user?.companies && user?.companies[tenantId]?.A1_COD?.trim()) ref = await dispatch('mountedFilters', { ref, filters: JSON.parse(filtersClient), register, forceFilter, tenantId, userLogged: user })
        else if (filtersSaller) ref = await dispatch('mountedFilters', { ref, filters: JSON.parse(filtersSaller), register, forceFilter, tenantId, userLogged: user })
      }

    
      if (!ref) return this._vm.$set(state.loading, [register], false)
      
      ref = ref.where('lifeControl.deletedAt', '==', null)
      if (filial) ref = ref.where('filial', '==', filial)
      // lembrar de mudar os SNAP's e retirar essa palhaçada do int
      // if (gpEmpresa) ref = ref.where('gpEmpresa', 'in', [gpEmpresa.slice(0, -1)*1, gpEmpresa.slice(0, -1)])
      if (gpEmpresa) ref = ref.where('gpEmpresa', '==', gpEmpresa.slice(0, -1))

      // ORDER BY
      let order = (Array.isArray(orderBy)) ? orderBy : [orderBy]
      for (const el of order) {
        if (el && typeof el == 'string') {
          ref = ref.orderBy(el)
        } else if (el && typeof el != 'string') {
          ref = ref.orderBy(el.key, el.mod)
        } else {
          ref = ref.orderBy(this._vm.$db.FieldPath.documentId(), 'desc')
        }
      }

      // START AT
      if (query) { 
        ref = (!order[0] || order[0].mod == 'desc') ? ref.startAt(`${query}\uf8ff`).endAt(query) : ref.startAt(query).endAt(`${query}\uf8ff`)
        commit('resetRegister', register)
      }

      // LIMIT
      ref = ref.limit(limit)

      // Grava Referencia e register para paginação
      if (!state.observers[register]) { 
        state.observers[register] = {} 
      }

      this._vm.$set(state.observers[register], 'ref', ref)
      this._vm.$set(state.observers[register], 'register', register)
      this._vm.$set(state.observers[register], 'details', details)
      this._vm.$set(state.observers[register], 'orderBy', orderBy)

      // SNAPSHOT
      const objMonitor = {
        origin: 'VueJS',
        function: 'Observer',
        collection, 
        register,
        filters,
        orderBy,
        query,
        limit,
        ref,
        forceFilter
      }
      const registerSnapshot = ref.onSnapshot(snapshot => dispatch('processregister', { snapshot, collection, register, details, orderBy, objMonitor, notUnshift }))

      // Grava listener para encerramento posterior
      if (state.observers[register].snapshots && state.observers[register].snapshots.length > 0) { 
        state.observers[register].snapshots.push(registerSnapshot) 
      } else { 
        state.observers[register].snapshots = [registerSnapshot] 
      }

      this._vm.$set(state.observers[register], 'snapshots', state.observers[register].snapshots)
    },

    nextObserver({ state, dispatch }, { register, limit }) {
      let ref = state.observers[register].ref
      const collection = state.observers[register].register
      const details = state.observers[register].details
      const orderBy = state.observers[register].orderBy
      const lastDocCursor = state.observers[register].lastDocCursor
      if (!lastDocCursor) { return }

      let startAfter = ''
      if (lastDocCursor.length == 1) {
        startAfter = lastDocCursor[0]
      } else {
        for (const el of lastDocCursor) {
          startAfter += `'${el}', `
        }
        startAfter = startAfter.slice(0, -2)
      }

      if (limit)  ref = ref.limit(limit)

      const registerSnapshot = ref.startAfter(startAfter)
        .onSnapshot(snapshot => {
          // console.info('nextObserver', collection, register, details, orderBy, limit, snapshot)
          const objMonitor = {
            origin: 'VueJS',
            function: 'nextObserver',
            collection, 
            register,
            orderBy,
            ref,
          }
          dispatch('processregister', { snapshot, collection, register, details, orderBy, objMonitor })
        })

      // Grava listener para encerramento posterior
      if (state.observers[register].snapshots && state.observers[register].snapshots.length > 0) { 
        state.observers[register].snapshots.push(registerSnapshot) 
      } else { 
        state.observers[register].snapshots = [registerSnapshot] 
      }
    },
    async processregister({ state, commit, dispatch }, { snapshot, collection, register, details, orderBy, objMonitor, notUnshift = false }) {
      // console.info('processregister', {snapshot, collection, register, details, orderBy, objMonitor, notUnshift})
      dispatch('snapshotMonitor', {snapshot, obj: objMonitor})

      const changes = snapshot.docChanges()
      const registers = []
      const size = state.registers[register].length
      for (const [idx, el] of changes.entries()) {
        const data = { id: el.doc.id, ...el.doc.data() }
        registers.push(data)
        if (el.type == 'added' || el.type == 'modified') {
          // O firebase sempre busca um item no banco caso haja uma deleção para manter a quantidade de itens solicitadas na query. Esse novo item substituto com newIndex 29.
          // Para evitar que esse item entre no topo da fila sempre que o newIndex não for 29 e já tiver itens no array faremos um push na fila, obrigando o item substituto entrar no final como esperado.
          // Quando estivermos mandando uma carga de pesquisa do mongo usaremos o notUnshift para forçar o push
          commit('setRegister', { register, data, unshift: (size > 0 && el.newIndex < 24 && !notUnshift) ? true : false })
        }
        else if (el.type == 'removed') { 
          commit('removeRegister', { register, data }) 
        }
      }

      // GET Detalhes em outras collections baseada nos registros encontrados
      if (details) {
        registers.forEach(register => {
          details.forEach(detail => {
            dispatch('getDetails', { item: this._vm.$_.cloneDeep(register), detail: this._vm.$_.cloneDeep(detail) })
          });
        });
      }

      // Grava último id para próxima iteração
      const last = snapshot.docs[snapshot.docs.length - 1];
      let lastDocCursor = null
      if (snapshot.docs.length > 0) {
        let order = (Array.isArray(orderBy)) ? orderBy : [orderBy]
        lastDocCursor = []
        for (const el of order) {
          if (el && typeof el == 'string') {
            lastDocCursor.push(last.data()[el])
          } else if (el && typeof el != 'string' && el.key.includes('.')) {
            const path = el.key.split('.')
            let lastData = last.data()
            for (const p of path) {
              lastData = lastData[p]
            }
            lastDocCursor.push(lastData)
          } else if (el && typeof el != 'string') {
            lastDocCursor.push(last.data()[el.key])
          } else {
            lastDocCursor.push(last.id)
          }
        }
      }
      this._vm.$set(state.observers[register], 'lastDocCursor', lastDocCursor)
      this._vm.$set(state.loading, [register], false)
    },

    // GET Details
    async getDetails({ dispatch }, { item, detail, endSlice = 1 }) {
      const { collection, register, filters, orderBy } = detail
      let get = true
      // Replace Filters
      filters.forEach(elx => {
        if (typeof elx.value == 'string' && elx.value.includes('res.')) {
          const path = elx.value.split('.')
          path.shift()
          let value = this._vm.$_.cloneDeep(item)
          path.forEach(ely => {
            const newValue = value[ely]
            if (value && Array.isArray(newValue) && newValue.length > 0) value = newValue.filter(x => x).slice(0, endSlice)
            else if (value && typeof newValue == 'string' && newValue) value = [newValue]
            else if (value && typeof newValue == 'boolean') value = newValue
            else if (value && typeof newValue == 'object' && !Array.isArray(newValue) && newValue) value = newValue
            else {
              value = null
              get = false
            }
          });
          elx.value = value
        }
      });
      if (get) dispatch('get', { collection, register, filters, orderBy })
    },

    snapshotMonitor({}, {snapshot, obj}) {
      if (!snapshot.empty) {
        if (snapshot.size > 1000) {
          console.warn(obj, `Model Get ${snapshot.size} Leituras`)
          const subject = `UF Manage - Alerta de leitura: ${snapshot.size} Leituras - ${obj.origin} - ${obj.collection}`
          let message = `<strong>${subject}</strong> <br/><br/>`
          delete obj.ref
          obj.orderby = JSON.stringify(obj.orderby)
          obj.filters = JSON.stringify(obj.filters)
          for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
              const el = obj[key];
              message += `<strong>${key}:</strong> ${el}<br/>`
            }
          }
          let data = { 
            recipient: [
              'joao.krabbe@userfunction.com.br',
              'gilson.rodrigues@userfunction.com.br',
              'sidney.sales@userfunction.com.br'
            ],
            subject,
            message,
          }
          this._vm.$api.post(`/mail/send`, data)//.then(res => console.info(res))
        }
      }
    },

    // GET
    async get({ state, commit, dispatch, rootState }, { collection, register = collection, filters, disableIntFilters = false, orderBy, query, limit = 50, ref, setRegister = true, forceFilter, customerId = null, resetRegister }) {
      const integration = (state.registers.integrations) ? state.registers.integrations.find(x => x.collection === collection) : null
      const user = rootState.auth.user

      // PREPARE ENVIROMENT
      const tenantId = customerId || await dispatch('getCustomer', collection)
      if (tenantId === null || typeof tenantId === 'undefined') 
      {
        throw new Error(`Nenhum ID do Grupo encontrado. Collection: ${collection}`)
      }
      const gpEmpresa = await dispatch('getGpEmpresa', collection)
      const filial = await dispatch('getFilial', collection)
      
      collection = tenantId ? `customers/${tenantId}/${collection}` : collection

      if (!ref) {
        ref = this._vm.$db().collection(collection)
      }
      
      // FILTERS
      if (filters) ref = await dispatch('mountedFilters', { ref, filters, register, forceFilter, tenantId, userLogged: user })
      if (integration && !disableIntFilters) {
        let { filtersSaller, filtersClient } = integration
        if (filtersClient && user?.companies && user?.companies[tenantId]?.A1_COD?.trim()) ref = await dispatch('mountedFilters', { ref, filters: JSON.parse(filtersClient), register, forceFilter, tenantId, userLogged: user })
        else if (filtersSaller) ref = await dispatch('mountedFilters', { ref, filters: JSON.parse(filtersSaller), register, forceFilter, tenantId, userLogged: user })
      }

      if (!ref) return
      
      ref = ref.where('lifeControl.deletedAt', '==', null)
      if (filial) ref = ref.where('filial', '==', filial)
      if (gpEmpresa) ref = ref.where('gpEmpresa', '==', gpEmpresa.slice(0, -1))
      
      // ORDER BY
      if (limit != 1) {
        let order = (Array.isArray(orderBy)) ? orderBy : [orderBy]
        for (const el of order) {
          if (el && typeof el == 'string') {
            ref = ref.orderBy(el)
          } else if (el && typeof el != 'string') {
            ref = ref.orderBy(el.key, el.mod)
          } else {
            ref = ref.orderBy(this._vm.$db.FieldPath.documentId())
          }
        }
      }
      
      // START AT
      if (query) { ref = ref.startAt(query).endAt(`${query}\uf8ff`) }

      // LIMIT
      if (limit) ref = ref.limit(limit)
      
      const registerSnapshot = await ref.get()

      const obj = {
        origin: 'VueJS',
        function: 'GET',
        collection, 
        register,
        filters,
        orderBy,
        query,
        limit,
        ref,
        setRegister,
        forceFilter,
        customerId
      }
      dispatch('snapshotMonitor', {snapshot: registerSnapshot, obj})
      
      // console.info('get', collection, register, filters, orderBy, query, limit, ref, setRegister, registerSnapshot)

      const registers = []
      if (resetRegister) { commit('resetRegister', register)  }
      registerSnapshot.forEach(doc => {
        const data = { id: doc.id, ...doc.data() }
        registers.push(data)
        if (register && setRegister) commit('setRegister', { register, data })
      });
      if (registers.length === 0 && register && setRegister) {
        commit('resetRegister', register )
      }
      return registers
    },

    // GET EXTERNAL REQUEST
    async getExternal(
      { commit, dispatch, rootState }, 
      { 
        register, 
        query, 
        ordem, 
        idField, 
        id, 
        search, 
        fieldsToSearch, 
        fieldsToMark = [], 
        filters, 
        setRegister = true, 
        path = "",
        page = 1, 
        perPage = 45, 
        nextPage = false, 
        filtersIn = [], 
        allRegs = false, 
        async = false, 
        customerId = null, 
        groupBy = null,
        contentToMarkForced = null,
        params={},
        resetRegister=false,
        timeout = 600000,
        forceTrim = false,
        isRestNode = false
      }) {
      const tenantId = customerId || await dispatch('getCustomer', 'e')
      
      // Disponivel para Filtro
      const gpEmpresa = await dispatch('getGpEmpresa', 'e')
      const filial = await dispatch('getFilial', 'e')
      const user = rootState.auth.user 
      if (!user) { return [] }
      const searchred = (search && fieldsToSearch && fieldsToSearch.length > 0)
      const filtered = (filters && filters.length > 0)
      const filteredIn = (filtersIn && filtersIn.length > 0 && filtersIn.filter(f => f.selecteds.length > 0))
      const superAdmin = user && user.superAdmin
      const admin = (user && user.companies && user.companies[rootState.companySelected.groupCompany]) ? user.companies[rootState.companySelected.groupCompany].admin : false

      if (resetRegister) {
        commit('resetRegister', (searchred || filtered || filteredIn) ? `${register}Filtered${allRegs?'All':''}` : `${register}${allRegs?'All':''}`)
      }

      // if (allRegs) {
        commit('resetRegister', `${register}FilteredAll`)
        commit('resetRegister', `${register}All`)
      // }
     
      if (!nextPage && !id) {
        commit('resetRegister', `${register}Filtered`)
        commit('setCounter', {counter: `${register}Filtered`, count: 0})
      }
      if (page == 1 && !async) commit('setLoading', {register, status: true})

      let querySearch = ''
      if (searchred) {
        querySearch += ' AND ('
        for (const att of fieldsToSearch) {
          querySearch += `UPPER(${att}) LIKE '%${search.toUpperCase()}%' OR `
        }
        querySearch = querySearch.slice(0, -3)+')'
      }

      let queryFilter = ''
      if (filtered) {
        for (const values of filters) {
          for (let value of values) {
            value = value.split(':')
            queryFilter += ` AND ${value[0]} = '${value[1]}'`
          }
        }
      }

      let queryFilteredIn = ''
      if (filteredIn) {
        const queryFiltersIn = filtersIn.map((f, index) => {
          const {field, selecteds, orAnd = 'AND', multiple = false} = f
          if (multiple) {
            const values = selecteds && selecteds.length > 0 ? selecteds.map(s => `'${(s+'').split('<em>').join('').split('</em>').join('')}'`).join(",") : "''"
            if (typeof field === 'string')
              return  `${field} IN (${values}) `
            else 
              return `(${field.map(f => `${f} IN (${values})`).join(` ${orAnd} `)})`
          } else if (selecteds) {
            return ` ${field} = ${selecteds} `
          }
        }).join(' AND ')
        queryFilteredIn += ` AND ${queryFiltersIn} `
      }
      
      if (query.includes('${')){ 
        if (!query.includes('`')) { query = "`"+query+"`" }
        const queryEval = eval(query)
        
        if (typeof queryEval === 'string') { 
          query = queryEval + querySearch + queryFilter + queryFilteredIn
        } else if (typeof queryEval === 'function') { 
          query = queryEval({querySearch, queryFilter, queryFilteredIn, user})
        }
      } else if (query.includes('`')) {
        query = eval(query) + querySearch + queryFilter + queryFilteredIn
      } else {
        query += querySearch + queryFilter + queryFilteredIn
      }

      if (id) query = `SELECT * FROM (${query}) AS SQL WHERE ${idField} = '${id}' `

      if (groupBy) {
        query = ` SELECT ${groupBy.fieldsGroup ? groupBy.fieldsGroup + ', ' + groupBy.fieldsGroupID : ''} ${groupBy.fieldsAcc ? (groupBy.fieldsGroup ? ', ' : '') + groupBy.fieldsAcc : ''} FROM(${query}) AS QRY ${groupBy.fieldsGroup ? ' GROUP BY ' + groupBy.fieldsGroup : ''} `
      }
      if (async) {
        try {
          // console.info(register, ordem, idField, id, search, fieldsToSearch, filters, setRegister, page, perPage, nextPage, query)  
          const res = await this._vm.$api.post('/protheus?path=RESTQRY', {
            "PAGINA": page,
            "PORPAGINA": allRegs ? 99999999999 : perPage,
            "QUERY": query,
            "ORDEM": ordem
          }, {noToast: false, tenantId, timeout, isRestNode })
          if (!res || !res.data || !res.data) return []
          let {PROXIMO, TOTAL, RETORNOS} = res.data
          if (register && setRegister) {
            commit('setCounter', {collection: (searchred || filtered || filteredIn) ? `${register}Filtered` : register, count: TOTAL})
  
            for (const el of RETORNOS) {
              if (forceTrim) {
                Object.keys(el).filter(k => typeof el[k] === 'string').forEach(k => el[k] = el[k].trim())
              }
              el.id = (el[idField]) ? el[idField] : el.LINE
              if (searchred || contentToMarkForced) {
                for (const att of fieldsToSearch) {
                  if (el[att]) {
                    el[att] = ((el[att]+'').toUpperCase()).replace((search || contentToMarkForced).toUpperCase(), `<em>${(search || contentToMarkForced).toUpperCase()}</em>`)
                  } else {
                    const fieldMarked = fieldsToMark.find(fm => fm.searchableContent === att)
                    if (fieldMarked)
                    el[att] = ((el[fieldMarked.value]+'').toUpperCase()).replace((search || contentToMarkForced).toUpperCase(), `<em>${(search || contentToMarkForced).toUpperCase()}</em>`)
                  }
                }
              }
              commit('setRegister', { register: (searchred || filtered || filteredIn) ? `${register}Filtered${allRegs?'All':''}` : `${register}${allRegs?'All':''}`, data: el })
            }
          }
          RETORNOS = this._vm.$_.cloneDeep(RETORNOS)
          return (id) ? RETORNOS[0] : RETORNOS
        } catch (error) {
          if (error?.response?.status) {
            console.error('error', error.response.status, error.response.data.error)  
          } else {
            console.error('error', error)  
          }
          return error
        }
      } else {
        // console.info(register, ordem, idField, id, search, fieldsToSearch, filters, setRegister, page, perPage, nextPage, params, query)
        return this._vm.$api.post('/protheus?path=RESTQRY'+path, {
          "PAGINA": page,
          "PORPAGINA": allRegs ? 99999999999 : perPage,
          "QUERY": query,
          "ORDEM": ordem
        }, {noToast: false, tenantId, timeout, isRestNode})
        .then(res => {
          if (!res || !res.data || !res.data) return []
         
          let {PROXIMO, TOTAL, RETORNOS} = res.data
          if (register && setRegister) {
            commit('setCounter', {collection: (searchred || filtered || filteredIn) ? `${register}Filtered` : register, count: TOTAL})

            let index = 0
            for (const el of RETORNOS) {
              index++
              if (forceTrim) {
                Object.keys(el).filter(k => typeof el[k] === 'string').forEach(k => el[k] = el[k].trim())
              }
              el.id = (el[idField]) ? el[idField] : el.LINE
              if (searchred || contentToMarkForced) {
                for (const att of fieldsToSearch) {
                  if (el[att]) {
                    el[att] = ((el[att]+'').toUpperCase()).replace((search || contentToMarkForced).toUpperCase(), `<em>${(search || contentToMarkForced).toUpperCase()}</em>`)
                  } else {
                    const fieldMarked = fieldsToMark.find(fm => fm.searchableContent === att)
                    if (fieldMarked && el[fieldMarked.value]) {
                      el[fieldMarked.value] = ((el[fieldMarked.value]+'').toUpperCase()).replace((search || contentToMarkForced).toUpperCase(), `<em>${(search || contentToMarkForced).toUpperCase()}</em>`)
                    }
                  }
                }
              }
              commit('setRegister', { register: (searchred || filtered || filteredIn) ? `${register}Filtered${allRegs?'All':''}` : `${register}${allRegs?'All':''}`, data: el })
            }
          }
          
          RETORNOS = this._vm.$_.cloneDeep(RETORNOS)
          return (id) ? RETORNOS[0] : RETORNOS
        })
        .catch(error => {
          if (error?.response?.status) {
            console.error('error', error.response.status, error.response.data.error)  
          } else {
            console.error('error', error)  
          }
          return error
        })
        .finally(() => commit('setLoading', {register, status: false}))
      }
    },

    // GET BY ID
    async getById({ commit, dispatch }, { collection, register = collection, id, subCollection, subCollections = [subCollection], filtersSubcollection, filtersSubcollections = [filtersSubcollection], orderSubcollection, orderSubcollections = [orderSubcollection], setRegister = true, getFilial = true, customerId = null }) {

      // PREPARE ENVIROMENT
      const tenantId = customerId || await dispatch('getCustomer', collection)
      if (tenantId === null || typeof tenantId === 'undefined') {
        throw new Error(`Nenhum ID do Grupo encontrado. Collection: ${collection}`)
      }
      const gpEmpresa = await dispatch('getGpEmpresa', collection)
      const filial = await dispatch('getFilial', collection)
      if (getFilial) {
        id = gpEmpresa.slice(0, -1) + filial + id
      }

      collection = tenantId ? `customers/${tenantId}/${collection}` : collection
      
      let ref = this._vm.$db().collection(collection).doc(id)

      // ref = ref.where('lifeControl.deletedAt', '==', null)
      // if (filial) ref = ref.where('filial', '==', filial)
      // if (gpEmpresa) ref = ref.where('gpEmpresa', '==', gpEmpresa.slice(0, -1))

      const doc = await ref.get()
      
      // console.info('getById', collection, register, id, subCollection, setRegister, getFilial, doc)
      if (!doc.exists) return
      const data = { id: doc.id, ...doc.data() }
      
      if (subCollections && subCollections[0]) {
        for (const [ix, sub] of subCollections.entries()) {
          let refSub = this._vm.$db().collection(collection).doc(id).collection(sub)
          data[sub] = await dispatch('get', { ref: refSub, orderBy: orderSubcollections[ix], filters: filtersSubcollections[ix], limit: null, setRegister: false })
        }
      }

      if (register && setRegister) commit('setRegister', { register, data })
      return data
    },

    // CRUD
    async save({ commit, dispatch, state, rootState }, { collection, item, notToast = false, integration = false, collectionDetails, itemsDetails, submitToErp, oldVersion, addCollections }) {
      const originalCollection = collection
      const tenantId = await dispatch('getCustomer', collection)
      if (integration) {
        // PREPARE ENVIROMENT
        if (tenantId === null || typeof tenantId === 'undefined') throw new Error('Nenhum ID do Grupo encontrado')
        const gpEmpresa = await dispatch('getGpEmpresa', collection)
        const filial = await dispatch('getFilial', collection)

        item.gpEmpresa = gpEmpresa.slice(0, -1)
        item.filial = filial
        if (item.status == 'Erro no Envio') item.status = 'Rascunho'
        item.errorErp = ''
      }

      if (collection == 'users') {
        item.filial = await dispatch('getFilial', 'SA3')
      }

      if (collectionDetails) {
        item[collectionDetails] = itemsDetails.map((x, ix) => {
          return {
            ...x.data,
            gpEmpresa: item.gpEmpresa,
            filial: item.filial
          }
        })
      }

      // collection = customerId + collection
      commit('setLoading', { stats: true }, { root: true })
      const method = (item.id || item.uid) ? 'patch' : 'post'
      let urlMaster = (integration) ? `/integration` : ``
      urlMaster += (item.id) ? `/${collection}/${item.id}` : (item.uid) ? `/${collection}/${item.uid}`: `/${collection}`

      // console.info(collection, item, integration, collectionDetails, itemsDetails, submitToErp, urlMaster)
      return this._vm.$api[method](encodeURI(urlMaster), item, { tenantId })
        .then(async res => {
          const item = res.data.data
          if(addCollections){
            // PREPARE ENVIROMENT
            const gpEmpresa = await dispatch('getGpEmpresa', collectionDetails)
            const filial = await dispatch('getFilial', collectionDetails)


            const urlDetails = `/integration/batch/${collection}/${collectionDetails}?set=true&clearSubCollections=true`
            itemsDetails = itemsDetails.map((x, ix) => {
              return {
                ...x.data,
                id: (x.data.id && collection === 'ADA') ? x.data.id : res.data.data.id + (ix + 1).toString().padStart(4, '0'),
                idParent: res.data.data.id,
                gpEmpresa: gpEmpresa.slice(0, -1),
                filial
              }
            })
            await this._vm.$api.patch(urlDetails, itemsDetails, { tenantId })

          }
          if (collectionDetails) {

            // PREPARE ENVIROMENT
            const gpEmpresa = await dispatch('getGpEmpresa', collectionDetails)
            const filial = await dispatch('getFilial', collectionDetails)


            const urlDetails = `/integration/batch/${collection}/${collectionDetails}?set=true&clearSubCollections=true`
            itemsDetails = itemsDetails.map((x, ix) => {
              return {
                ...x.data,
                id: (x.data.id && collection === 'ADA') ? x.data.id : res.data.data.id + (ix + 1).toString().padStart(4, '0'),
                idParent: res.data.data.id,
                gpEmpresa: gpEmpresa.slice(0, -1),
                filial
              }
            })
            await this._vm.$api.post(urlDetails, itemsDetails, { tenantId })
          }

          // if (oldVersion) {
          //   const dataVersion = { idParent: res.data.data.id, ...oldVersion}
          //   delete dataVersion.id
          //   const urlDetails = `/integration/batch/${collection}/versions`
          //   await this._vm.$api.post(urlDetails, [dataVersion], { tenantId })
          // }

          // TODO Alterar para transação
          if (submitToErp) {
            item.submitToErp = true
            item.status = 'Pronto para o envio'
            item.bloqueado = true
            await this._vm.$api.patch(`/integration/${collection}/${item.id}`, item, { tenantId })
          }
          
          if (!notToast) {
            let message = 'Registro Salvo!'

            if (integration) {
              const _integration = state.registers.integrations.find(i => i.collection === originalCollection)
              if ( method == 'patch' && _integration?.editSuccessMessage
                || method == 'post'  && _integration?.registerSuccessMessage ) {
                try {
                  /**
                   * @TODO melhorar a segurança do eval.
                   * está permitindo acesso a todo o escopo do código e a execução de qualquer código,
                   * sendo assim uma falha de segurança.
                   */
                  message = method == 'patch' ? eval(_integration.editSuccessMessage) : eval(_integration.registerSuccessMessage)
                } catch (err) {
                  console.error('save: Error on eval successMessage', err)
                }
              }
            }

            this._vm.$toast.success(message, { position: 'top-right' })
          }

          return res
        })
        .catch(error => { throw (error.response && error.response.data) ? error.response.data.error : error })
        .finally(() => commit('setLoading', { stats: false }, { root: true }))
    },

    async remove({ commit, dispatch, state }, { collection, item, integration = false }) {
      commit('setLoading', { stats: true }, { root: true })
      const tenantId = await dispatch('getCustomer', collection)
      if (tenantId === null || typeof tenantId === 'undefined') throw new Error('Nenhum ID do Grupo encontrado')

      const originalCollection = collection

      let url = (integration) ? `/integration` : ``
      url += `/${collection}/${item.id}`
      if (item.id) {
        return this._vm.$api.delete(url, { tenantId })
          .then(res => {
            let message = 'Registro Deletado!'
            if (integration) {
              const _integration = state.registers.integrations.find(x => x.collection === originalCollection)
              if (_integration?.deleteSuccessMessage) {
                try {
                  /**
                   * @TODO melhorar a segurança do eval.
                   * está permitindo acesso a todo o escopo do código e a execução de qualquer código,
                   * sendo assim uma falha de segurança.
                   */
                  message = eval(_integration.deleteSuccessMessage)
                } catch (err) {
                  console.error('save: Error on eval successMessage', err)
                }
              }
            }
            this._vm.$toast.success(message, { position: 'top-right' })
            return res
          })
          .catch(error => {
            console.error(error)
            return error
          })
          .finally(() => commit('setLoading', { stats: false }, { root: true }))
      }
    },

    async generatePDF({ dispatch }, { dataPrint, register }) {
      // const tenantId = await dispatch('getCustomer', 'e')
      const tenantId = await dispatch('getCustomer', register)
      if (tenantId === null || typeof tenantId === 'undefined') throw new Error('Nenhum ID do Grupo encontrado')

      try {
        const { data } = await this._vm.$api.post('/generatepdf', {...dataPrint, pathFilePDF: `${register}/${dataPrint.header?.id || ''}`}, { timeout: 60*1000, noToast: true, tenantId })
        return data
      } catch(error) {
        console.error(error)
        throw error
      }

    },

    // ainda não foi refeito com o groupCompany
    async calculaImpostos({ dispatch, rootState }, { item, itemsDetails, collectionDetails }) {
      // const tenantId = await dispatch('getCustomer', 'e')
      const tenantId = await dispatch('getCustomer', 'SC5')
      if (tenantId === null || typeof tenantId === 'undefined') throw new Error('Nenhum ID do Grupo encontrado')
      if (collectionDetails) {
        const gpEmpresa = await dispatch('getGpEmpresa', collectionDetails)
        const filial = await dispatch('getFilial', collectionDetails)
        item.GPEMPRESA = gpEmpresa.slice(0, -1)
        item.FILIAL = filial
        item[collectionDetails] = []
        for (const x of itemsDetails) { item[collectionDetails].push({ ...x.data, GPEMPRESA: gpEmpresa, FILIAL: filial }) }
      }
      //TODO Arrumar limpeza do item
      delete item.DA0
      delete item.SA1
      delete item.SA3
      delete item.SA4
      delete item.SE4
      delete item._user
      return this._vm.$api.post('/protheus/calculaImpostos', item, { noToast: true, tenantId })
        .then(res => res.data.data)
        .catch(error => {throw error})
    },
    async base64Rascunho({ dispatch, rootState }, { item, itemsDetails, collectionDetails }) {
      // const tenantId = await dispatch('getCustomer', 'e')
      const tenantId = await dispatch('getCustomer', 'SC5')
      if (tenantId === null || typeof tenantId === 'undefined') throw new Error('Nenhum ID do Grupo encontrado')
      if (collectionDetails) {
        const gpEmpresa = await dispatch('getGpEmpresa', collectionDetails)
        const filial = await dispatch('getFilial', collectionDetails)
        item.GPEMPRESA = gpEmpresa.slice(0, -1)
        item.FILIAL = filial
        item[collectionDetails] = []
        for (const x of itemsDetails) { item[collectionDetails].push({ ...x.data, GPEMPRESA: gpEmpresa, FILIAL: filial }) }
      }
      //TODO Arrumar limpeza do item
      delete item.DA0
      delete item.SA1
      delete item.SA3
      delete item.SA4
      delete item.SE4
      delete item._user
      return this._vm.$api.post('/protheus/base64Rascunho', item, { noToast: true, tenantId })
        .then(res => res.data.data)
        .catch(error => {throw error})
    },
    async uploadFile( { state, dispatch }, { base64 , attachment, collection, item}) {
      const tenantId = await dispatch('getCustomer', collection)
      const gpEmpresa = await dispatch('getGpEmpresa', collection)
      const filial = await dispatch('getFilial', collection)
      const path = tenantId + '/' + collection + '/' + item.id
      const body = {base64:  {
          data: base64,
          fileName: path+'/'+attachment.name,
          fileField: tenantId + '_' + collection,
          mimeType: attachment.type
        }, 
        GPEMPRESA: gpEmpresa.slice(0, -1),
        filial: filial
      }
      
      return this._vm.$api.post('/chatbot/upload', body, { noToast: true, tenantId })
        .then(res => res.data.data)
        .catch(error => {throw error})
    },
    async calculaPreco({ dispatch, rootState },{ item, itemsDetails, collectionDetails }){
      // arrumar getCustomer, pois na WDCnet nao é SC5 mas Z30
      const tenantId = await dispatch('getCustomer', 'Z30')
      if (tenantId === null || typeof tenantId === 'undefined') throw new Error('Nenhum ID do Grupo encontrado')
      if (collectionDetails) {
        const gpEmpresa = await dispatch('getGpEmpresa', collectionDetails)
        const filial = await dispatch('getFilial', collectionDetails)
        item.GPEMPRESA = gpEmpresa.slice(0, -1)
        item.FILIAL = filial
        item[collectionDetails] = []
        for (const x of itemsDetails) { item[collectionDetails].push({ ...x, id: x.Z31_EAN, GPEMPRESA: gpEmpresa, FILIAL: filial }) }
      }
      delete item.DA0
      delete item.SA1
      delete item.SA3
      delete item.SA4
      delete item.SE4
      delete item._user
      if(item.Z30_CLIFIN){
        item.Z30_CLIENT = item.Z30_CLIFIN
        item.Z30_LOJA = item.Z30_LJFIN
      }
      return this._vm.$api.post('/protheus/calculaPreco', item, { noToast: false, tenantId })
        .then(res => res.data.data)
        .catch(error => {throw error})
    },

    // ainda não foi refeito com o groupCompany
    async sendFile({ dispatch}, { file, collection, numSite, entityKey, filEnt  }) {
      const gpEmpresa = await dispatch('getGpEmpresa', collection)            
      const options = { headers: {'Content-Type': 'multipart/form-data'} } 
      const oMsg = new FormData();
      
      oMsg.append('gpEmpresa', gpEmpresa.slice(0, -1))
      oMsg.append('entity', collection)
      oMsg.append('file', file)
      oMsg.append('numSite', numSite)
      oMsg.append('entityKey', entityKey)
      oMsg.append('filEnt', filEnt)

      return this._vm.$api.post('/protheus/upload',oMsg, options)
        .then(res => {
          res.data.data
          this._vm.$toast.success('Upload realizado!', { position: 'top-right' })
        })
        .catch(error => {
          this._vm.$toast.error('Erro no upload!', { position: 'top-right' })
        })
    },

    // ainda não foi refeito com o groupCompany
    async getFiles({ dispatch }, { collection, numSite, entityKey, filent  }) {
      const gpEmpresa = await dispatch('getGpEmpresa', collection)      
      
      return this._vm.$api.get(`/protheus?path=uftools/upload/files&emp=${gpEmpresa.slice(0, -1)}&cNumSite=${numSite}&cEntidade=${collection}&cKeyEntidade=${entityKey}&cFilent=${filent}`)
        .then(res => res.data.files )
        .catch(error => error)
    },

    // ainda não foi refeito com o groupCompany
    async getFile({ dispatch }, { collection, codObj  }) {
      const gpEmpresa = await dispatch('getGpEmpresa', collection)      
      const filial = '01'//TODO Verificar pq aqui sera o compartilhamento do BC
      
      return this._vm.$api.get(`/protheus?path=uftools/upload/file&emp=${gpEmpresa.slice(0, -1)}&cCodOBJ=${codObj}&_cFilial=${filial}&cEntidade=${collection}`)
        .then(res => res.data )
        .catch(error => error)
    },

    removeBundle({ commit }, { collection, itens }) {
      const ids = itens.map(x => x.id)
      commit('setLoading', { stats: true }, { root: true })
      if (itens.length > 0) {
        return this._vm.$api.patch(`/${collection}/remove`, ids)
          .then(res => {
            this._vm.$toast.success('Registros Deletados!', { position: 'top-right' })
            return res
          })
          .catch(error => {
            console.error(error)
            return error
          })
          .finally(() => commit('setLoading', { stats: false }, { root: true }))
      }
    },

    // ainda não foi refeito com o groupCompany
    // async count({ state, commit }, { collection }) {
    //   const snapshot = this._vm.$db()
    //     .collection('counts')
    //     .doc(collection)
    //     .collection('shards')
    //     .onSnapshot(snapshot => {
    //       let count = 0;
    //       for (const doc of snapshot.docs) {
    //         count += doc.get('count');
    //       }
    //       commit('setCounter', { collection, count })
    //     })

    //   // Grava listener para encerramento posterior
    //   if (state.observers['counts'].snapshots && state.observers['counts'].snapshots.length > 0) { state.observers['counts'].snapshots.push(snapshot) }
    //   else { state.observers['counts'].snapshots = [snapshot] }
    //   this._vm.$set(state.observers['counts'], 'snapshots', state.observers['counts'].snapshots)
    // },

    // ainda não foi refeito com o groupCompany
    import({ commit }, { collection, item }) {
      commit('setLoading', { stats: true }, { root: true })

      const options = { headers: { 'Content-Type': 'multipart/form-data' } }
      const payload = new FormData();
      for (const key in item) {
        switch (key) {
          case 'file':
            payload.append(key, item[key])
            break;
          default:
            payload.append(key, stringify(item[key]))
            break;
        }
      }

      const method = 'post'
      const url = `/${collection}/import`
      return this._vm.$api[method](url, payload, options)
        .then(res => {
          const { data } = res.data
          this._vm.$toast.success(data.message, { position: 'top-right' })
          return res
        })
        .catch(error => { throw error.response.data.error || error.response.data })
        .finally(() => commit('setLoading', { stats: false }, { root: true }))
    },

    // ainda não foi refeito com o groupCompany
    async searchAlgolian({ state, dispatch, rootState }, { index, query, facetFilters = [], restrictSearchableAttributes = [], attributesToRetrieve = ['*'], attributesToHighlight = [], hitsPerPage = 1000, item = {} }) {
      const user = rootState.auth.user
     
      if (state.counters.searchAlgolian > (rootState.algolia.searchLimit)) {
        this._vm.$toast.error('A consulta não pode ser concluída pois o limite de consultas do algolia já foi alcançado.')
        return { hits: [] }
      } 
      const compareFilters = (last, actual) => last.length == actual.length && actual.every((arr, idx) => arr.length == last.length && arr.every((str, idx2) => str == last[idx][idx2]))
      let algoliaX = state.lastAlgoliaReqParams.x || 1
      if (!rootState.algolia || (state.lastAlgoliaReqParams.query == query && compareFilters(state.lastAlgoliaReqParams.facetFilters, facetFilters) && algoliaX > 20)) {
        console.warn('searchAlgolian: previne busca com a mesma query mais de 20 vezes!')
        return { hits: [] }
      }
      if (state.lastAlgoliaReqParams.query == query && compareFilters(state.lastAlgoliaReqParams.facetFilters, facetFilters)) { algoliaX++ }
      state.lastAlgoliaReqParams = { query, facetFilters, x: algoliaX }

      // PREPARE ENVIROMENT
      const gpEmpresa = await dispatch('getGpEmpresa', index)
      const filial = await dispatch('getFilial', index)
      const tenantId = await dispatch('getCustomer', index)

      const indexPath = tenantId ? `${tenantId}_${index}` : index

      // FILTERS
      const integration = state.registers.integrations.find(x => x.collection === index)
      let filtersByIntegration = []
      if (integration) {
        let { filters, filtersSaller, filtersClient } = integration
         
        let filtersAlgolia = []
        if (filters) { filtersAlgolia = await dispatch('mountedFilters', { filters: JSON.parse(filters), register: index, tenantId, userLogged: user }) }
        if (filtersClient && user?.companies && user?.companies[tenantId]?.A1_COD?.trim()) { filtersAlgolia = [...filtersAlgolia, ...await dispatch('mountedFilters', { filters: JSON.parse(filtersClient), register: index, tenantId, userLogged: user })] }
        else if (filtersSaller) { filtersAlgolia = [...filtersAlgolia, ...await dispatch('mountedFilters', { filters: JSON.parse(filtersSaller), register: index, tenantId, userLogged: user })] }
 
        for (const [index, facet] of facetFilters.entries()) {
          if (facet && facet.value && facet.value.includes('${')) facet.value = eval(facet.value) 
          if (facet && facet.key) facetFilters[index] = [`${facet.key}:${facet.value}`]
        }
        
        for (const { key, operator, value } of filtersAlgolia) {
          filtersByIntegration.push([`${key}:${value}`])
        }
        
        if (filial && !filtersByIntegration.some(ff => {
          ff.some(f => f.includes('filial:'))
        })
        ) {
          // filtersByIntegration.push([`filial:${filial}`])
        }

        if (gpEmpresa && !filtersByIntegration.some(ff => 
            ff.some(f => f.includes('gpEmpresa:'))
          )
        ) {
          // filtersByIntegration.push([`gpEmpresa:${gpEmpresa.slice(0,-1)}`])
        }

      } else {
        console.warn(`searchAlgolian: Couldn't find integration '${index}'.`)
      }
      if (!query) {
        filtersByIntegration = filtersByIntegration.filter(ff => ff.some(f => !(f.includes('gpEmpresa:') || f.includes('filial:'))))
      }
      
      filtersByIntegration.push([`gpEmpresa:${gpEmpresa.slice(0,-1)}`])
      filtersByIntegration.push([`filial:${filial}`])

      const client = algoliasearch(rootState.algolia.appId, rootState.algolia.searchApiKey);
      const ix = client.initIndex(indexPath);
      return ix.search(query, {
        'facetFilters': [...facetFilters, ...filtersByIntegration],
        'restrictSearchableAttributes': restrictSearchableAttributes,
        'attributesToRetrieve': attributesToRetrieve,
        'attributesToHighlight': attributesToHighlight,
        'hitsPerPage': hitsPerPage
      })
        .then(result => {
          // console.info(index, indexPath, query, result, facetFilters, filtersByIntegration, restrictSearchableAttributes, attributesToRetrieve, attributesToHighlight, hitsPerPage)
          // this._vm.$api.put('/counters/searchAlgolian', {}, {})
          //   .then(res => res)
          //   .catch(error => { throw error.response.data.error || error.response.data })
          return { index, indexPath, ...result }
        })
        .catch(e => {
          console.error(e);
          throw e
        })
    },

    async getApi({dispatch}, { url, options, generic, timeout = 600000}) {
      try {
        const tenantId = await dispatch('getCustomer', 'e')
        if (generic) {
          url = `/protheus/genericRequest`
          if (options && options.params) 
            options.params = {...options.params, ...generic} 
          else 
            options = {params: generic}
        }

        const result = await this._vm.$api.get(url, {...options, tenantId, timeout })
        return result.data
        
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async getOnMongo({state, commit, dispatch, rootState}, payload){
      let {register, collection, subCollection, idParent = null, id, filters, orFilters, dateRange, fields, orderBy, getTotal, limit, getDeleteds, startAfter, resetRegister = false, onSnapshot = false, forceFilter = true, getFirestore = false, disableIntFilters = false, noState = false} = payload
      const originalRegister = register.replace('Filtered', '')
      commit('setLoading', {register, status: true})
      commit('setLoading', {register: originalRegister, status: true})

      // PREPARE ENVIROMENT
      const tenantId = await dispatch('getCustomer', 'e')
      let gpEmpresa = await dispatch('getGpEmpresa', subCollection || collection)
      gpEmpresa = gpEmpresa.slice(0, -1)
      const filial = await dispatch('getFilial', subCollection || collection)
      if (!idParent && subCollection) idParent = gpEmpresa+filial+id
      
      if (resetRegister) { commit('resetRegister', register)  }
      if (!startAfter) { getTotal = true}
      
      // FILTERS
      if (filters) {
        filters = filters.map(x => {
          let filter = Array.isArray(x) ? x[0] : null
          let operator = ''
          
          if (filter && filter.includes('like')) {
            filter = filter.split('like') 
            operator = 'like'
          }          
          if (filter && filter.includes(':')) {
            filter = filter.split(':') 
            operator = '=='
          }
          if (filter && filter.includes('~~')) {
            filter = filter.split('~~') 
            operator = '~~'
          }
          if (filter && filter.includes('~')) {
            filter = filter.split('~') 
            operator = '~'
          }
          if (filter && filter.includes('!=')) {
            filter = filter.split('!=') 
            operator = '!='
          }
          if (filter && filter.includes('>')) {
            filter = filter.split('>') 
            operator = '>'
          }
          if (filter && filter.includes('>=')) {
            filter = filter.split('>=') 
            operator = '>='
          }
          if (filter && filter.includes('<')) {
            filter = filter.split('<') 
            operator = '<'
          }
          if (filter && filter.includes('<=')) {
            filter = filter.split('<=') 
            operator = '<='
          }
          if (filter && filter.length > 1) {
            x = {
              key: filter[0],
              operator,
              value: filter[1]
            }
          }
          return x
        })
        filters = await dispatch('mountedFilters', { filters, register, tenantId, forceFilter })
        if (!filters) return
      } else {
        filters = []
      }

      const user = rootState.auth.user
      const integration = (state.registers.integrations) ? state.registers.integrations.find(x => x.collection === (subCollection || collection)) : null
      if (integration && !disableIntFilters) {
        let { filters:_filters, filtersSaller, filtersClient } = integration

        if (_filters) { _filters = await dispatch('mountedFilters', { filters: JSON.parse(_filters), register, tenantId, userLogged: user }) } else { _filters = [] }

        if (filtersClient && user?.companies && user?.companies[tenantId]?.A1_COD?.trim()) {
          filtersClient = await dispatch('mountedFilters', { filters: JSON.parse(filtersClient), register, tenantId, userLogged: user })
        } else { filtersClient = [] }
        
        if (filtersSaller) {
          filtersSaller = await dispatch('mountedFilters', { filters: JSON.parse(filtersSaller), register, tenantId, userLogged: user }) 
        } else {
          filtersSaller = []
        }
        if (_filters) filters = [...filters, ..._filters]
        if (filtersClient) filters = [...filters, ...filtersClient]
        if (filtersSaller) filters = [...filters, ...filtersSaller]
      }

      if (onSnapshot || getFirestore) { 
        if (!fields) fields = []
        fields.push('idFirestore')
      }

      return this._vm.$api.get(`/mongodb/${(subCollection) ? `${collection}.${subCollection}` : collection}`, {
        tenantId,
        noToast: true,
        params: {
          superUser: user.superAdmin ? true : false, 
          gpEmpresa: gpEmpresa,
          filial,
          idParent,
          filters: JSON.stringify(filters),
          orFilters: JSON.stringify(orFilters),
          dateRange: JSON.stringify(dateRange),
          fields: JSON.stringify(fields),
          orderBy: (typeof orderBy != 'string') ? JSON.stringify(orderBy) : orderBy,
          getTotal,
          limit,
          getDeleteds,
          startAfter
        }
      })
        .then(async res => {
          if (res.status == 200) {
            const {meta, data} = res.data
            
            if (onSnapshot || getFirestore) {
              if (getFirestore) res.data.data = []
              // Em tempo real
              const ids = data.map(x => x.id)
              if (ids && ids.length > 0) {
                const chunks = []
                for (let ix = 0; ix < ids.length/10; ix++) {
                  chunks.push(ids.slice(ix*10, (ix+1)*10))
                }
                for (const el of chunks) {
                  if (onSnapshot) {
                    dispatch(
                      'observer', 
                      {
                        collection, 
                        register, 
                        filters: [{
                          key: this._vm.$db.FieldPath.documentId(), 
                          operator: 'in', 
                          value: el
                        }],
                        limit: el.length,
                        disableIntFilters: true,
                        notUnshift: true,
                        forceFilter
                      }
                    )
                  } else if (getFirestore) {
                    const regs = await dispatch(
                      'get', 
                      {
                        collection: (subCollection && idParent) ? `${collection}/${idParent}/${subCollection}` : collection, 
                        register, 
                        filters: [{
                          key: this._vm.$db.FieldPath.documentId(), 
                          operator: 'in', 
                          value: el
                        }],
                        limit: el.length,
                        disableIntFilters: true,
                        forceFilter,
                        resetRegister
                      }
                    )
                    for (const reg of regs) {
                      const ix = res.data.data.findIndex(x => x.id === reg.id)
                      if (ix > -1) {
                        res.data.data[ix] = reg
                      } else {
                        res.data.data.push(reg)
                      }
                    }
                  }
                  // desabilida o reset após a primeira pagina
                  resetRegister = false
                }
              }

              if (getFirestore) {
                res.data.data = res.data.data.sort((a, b) => {
                  if (!Array.isArray(orderBy)) { orderBy = [orderBy] }
                  
                  for (const el of orderBy) {
                    let field = el.field || el.key
                    let mod = 1
                    if (field) {
                      mod = (el.mod && (el.mod.toLowerCase() == 'desc' || el.mod == -1)) ? -1 : 1
                    } else {
                      field = el
                    } 
                    if (!field) { return 0 }
                    if (a[field] < b[field]) return -1*mod
                    if (a[field] > b[field]) return 1*mod
                    continue
                  }
                  return 0
                })
              }
            } else {
              // Estatico
              for (const el of data) {
                commit('setRegister', { register, data:el })
                if (register.includes('Filtered')) { commit('setRegister', { register: originalRegister, data:el }) }
              }
            }

            // Referencias para paginação
            if (!state.mongodb[register]) {
              state.mongodb[register] = {}
            }
            if (!noState) { this._vm.$set(state.mongodb[register], 'payload', payload) }
            if (meta && meta.lastDocCursor) {
              if (!noState) { this._vm.$set(state.mongodb[register], 'lastDocCursor', meta.lastDocCursor) }
            } else {
              if (!noState) { this._vm.$set(state.mongodb[register], 'lastDocCursor', null) }
            }
          }

          return res
        })
        .then(res => (res.status == 200) ? res.data : null)
        .catch(err => {
          if (!noState && state.mongodb[register]) this._vm.$set(state.mongodb[register], 'lastDocCursor', null)
          return err
        })
        .finally(() => {
          // console.info('getOnMongo', {collection, subCollection, originalRegister, register, idParent, filters, orFilters, dateRange, fields, orderBy, getTotal, limit, getDeleteds, startAfter, onSnapshot, getFirestore})
          commit('setLoading', {register, status: false})
          commit('setLoading', {register: originalRegister, status: false})
        })
    },
    async nextOnMongo({ state, dispatch }, { register, limit }) {
      const lastDocCursor = state.mongodb[register].lastDocCursor
      if (!lastDocCursor) { return }
      const payload = state.mongodb[register].payload
      if (limit) payload.limit = limit
      return await dispatch('getOnMongo', {...payload, getTotal: false, startAfter: lastDocCursor})
    }
  }
}

export default register
